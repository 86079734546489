.Signup-main {
  margin: 0 auto;
  width: 940px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: auto;
  padding-top: 120px;
  padding-bottom: 180px;
}

.Signup-logoside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 87.5px;
}

.Signup-logoside-logo {
  width: 283px;
  height: 283px;
  object-fit: contain;
}

.Signup-logoside-h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

x.Signup-logoside-h2 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Signup-infoside {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.Signup-infoside-backbtn {
  width: 176px;
  height: 32px;
  padding: 5px 10px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  background: none;
  text-align: center;
}

.Signup-infoside-box {
  width: 420px;
  height: auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.Signup-box-title {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.Signup-box-alert {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.Signup-info-label {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.Signup-info-asterisk {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.Signup-info-h1 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c96161;
  text-align: left;
}

.Signup-info-h2 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #ffffff;
}

.Signup-dropdown {
  width: 80px;
  height: 32px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
}

.Signup-dropdown-img {
  width: 20px;
  height: 17px;
}

.Signup-dropdown-label {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.Signup-dropdown-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 4px 0 4px;
  border-color: #ffffff transparent transparent transparent;
}

.Signup-dropdown-control {
  width: 120px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
  background-color: #242322;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 32px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.signup-dropdown-menu {
  width: 120px;
  height: 32px;
  padding: 0 10px;
  box-shadow: inset 0 -1px 0 0 #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: none;
  border: none;
}

.Signup-phone-input {
  width: 130px;
  height: 32px;
  padding: 6px 10px 6px 10px;
  border-radius: 8px;
  border: solid 1px #ffffff;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background: none;
}

.Signup-input {
  width: 220px;
  height: 32px;
  padding: 6px 10px 6px 10px;
  border-radius: 8px;
  border: solid 1px #ffffff;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background: none;
}

.Signup-confirm {
  width: 140px;
  height: 32px;
  padding: 6px 10px;
  border-radius: 8px;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c1b1a;
  border: none;
}

.Signup-codetime-h1 {
  margin: 0 15px;
  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  opacity: 0.5;
}

.Signup-input-full {
  width: 380px;
  height: 32px;
  padding: 6px 10px 6px 10px;
  border-radius: 8px;
  border: solid 1px #ffffff;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background: none;
}

.Signup-input-certnum {
  width: 140px;
  height: 32px;
  padding: 6px 10px 6px 10px;
  border-radius: 8px;
  border: solid 1px #ffffff;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background: none;
}

.Signup-input-certnum:disabled {
  opacity: 0.3;
}

.Signup-certnum-description {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Signup-button {
  width: 380px;
  height: 44px;
  margin: 0 auto;
  padding: 12px 120px 12px 120px;
  border-radius: 8px;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  border: none;
}

.Signup-select-h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Signup-select-h2 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Signup-select-btn {
  width: 300px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Signup-select-btn h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.Signup-select-btn h2 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #fff;
}

.Signup-select-btn button {
  width: 100%;
  height: 44px;
  padding: 12px;
  border-radius: 8px;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  border: none;
}

.Signup-term {
  width: 420px;
  height: auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.Signup-term h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e0be7a;
}

.Signup-term h2 {
  width: 360px;
  height: 500px;
  padding: 10px;
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  overflow: auto;
  border-radius: 8px;
  background-color: #1c1b1a;
}

.Signup-term h3 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.Signup-term-button {
  width: 100%;
  height: 44px;
  padding: 12px;
  border-radius: 8px;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  border: none;
}

.Signup-select-business {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Signup-select-business h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Signup-select-business h2 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Signup-select-business h3 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.Signup-select-business-box {
  width: 240px;
  height: 207px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
}

.Signup-select-business-box h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.Signup-select-business-box h2 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Signup-select-business-box-selected {
  width: 240px;
  height: 207px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #e0be7a;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
}

.Signup-select-business-box-selected h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
}

.Signup-select-business-box-selected h2 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
}

.Signup-select-business-b1 {
  width: 260px;
  height: 44px;
  padding: 12px;
  border-radius: 8px;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  border: none;
}

.FutureTrading-list {
  width: 295px;
  height: 888px;
  border-radius: 8px;
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.FutureTrading-list div.title {
  margin: 13px 20px;
  font-family: NotoSansKR;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.FutureTrading-list div.line {
  width: 100%;
  height: 1px;
  opacity: 0.15;
  border: solid 1px #fff;
}

.FutureTrading-list div.sort {
  margin: 20px 20px 10px 20px;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
}

.FutureTrading-list button.dropdown-button {
  width: 255px;
  height: 32px;
  padding: 6px;
  border-radius: 8px;
  border: none;
  background-color: #1c1b1a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
  margin: 0 20px 13px 20px;
}
.FutureTrading-list div.dropdown {
  position: relative;
  display: inline-block;
}
.FutureTrading-list div.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffdab9;
  width: 255px;
  height: 32px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  background-color: #242322;
  margin: -13px 20px;
  z-index: 1;
}
.FutureTrading-list div.dropdown-content button {
  text-decoration: none;
  display: block;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
  width: 100%;
  height: 32px;
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.15);
  background-color: #242322;
  align-items: center;
  border: none;
  z-index: 1;
}
.FutureTrading-list div.dropdown-content button:hover {
  background-color: #fff;
  color: #242322;
  border: none;
  z-index: 1;
}
.FutureTrading-list div.dropdown:hover .dropdown-content {
  display: block;
  z-index: 1;
}
.FutureTrading-list div.dropdown:hover .dropdown-button {
  background-color: #fff;
  color: #242322;
  border: none;
  z-index: 1;
}

.FutureTrading-list div.ticket {
  width: 255px;
  height: 56px;
  margin: 0 20px;
  padding: 7px 1.5px 11px 0;
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
}

.FutureTrading-list div.ticket div.point {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FutureTrading-list div.ticket h1 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #91bc6a;
  text-align: left;
}

.FutureTrading-list div.ticket h2 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c96161;
  text-align: left;
}

.FutureTrading-list div.ticket h3 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.FutureTrading-list div.ticket h4 {
  opacity: 0.5;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
}

.FutureTrading-chart {
  width: 1380px;
  height: 670px;
  overflow: hidden;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-items: center;
  text-align: center;
}

.FutureTrading-chart div.bettingList {
  width: 524px;
  height: 231px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  background-color: #242322;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.FutureTrading-chart div.bettingList div.title {
  height: 56px;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  padding: 13px 20px;
}

.FutureTrading-chart div.bettingList div.line {
  height: 1px;
  opacity: 0.15;
  border: solid 1px #fff;
}

.FutureTrading-chart div.bettingList div.ticketTitle {
  height: 56px;
  display: grid;
  grid-template-columns: 107px 114px 182px 80px;
  padding: 10px 20px;
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.15);
}

.FutureTrading-chart div.bettingList div.ticketTitle h1 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
}

.FutureTrading-chart div.bettingList div.ticket {
  height: 56px;
  align-items: center;
  display: grid;
  grid-template-columns: 107px 114px 182px 80px;
  padding: 0 20px;
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.15);
}

.FutureTrading-chart div.bettingList div.ticket h1 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
}

.FutureTrading-chart div.bettingList div.ticket h2 {
  opacity: 0.5;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
}

.FutureTrading-chart div.bettingList div.ticket h3 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #91bc6a;
  text-align: left;
}

.FutureTrading-chart div.bettingList div.ticket h4 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c96161;
  text-align: left;
}

.FutureTrading-chart div.bettingList div.ticket button {
  width: 80px;
  height: 36px;
  padding: 9px 0;
  background-color: #1c1b1a;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
  text-decoration: underline;
  border: none;
}

.FutureTrading-chart img.icon {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
}

.FutureTrading-chart div.label {
  opacity: 0.5;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
  position: absolute;
  top: 12px;
  left: 45px;
}

.FutureTrading-chart div.btns {
  position: absolute;
  top: 6px;
  left: 190px;
  display: flex;
  flex-direction: row;
}

.FutureTrading-chart div.btns button {
  height: 30px;
  margin-right: 5px;
  padding: 5px 14px;
  border-radius: 4px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  opacity: 0.75;
  border: none;
}

.FutureTrading-chart div.askbid {
  width: 135px;
  height: 56px;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(36, 36, 34, 0.75);
  position: absolute;
  bottom: 121px;
  right: 22px;

  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
}

.FutureTrading-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FutureTrading-menu h1 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.FutureTrading-menu h2 {
  font-family: NotoSansKR;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.FutureTrading-menu div.box {
  width: 125px;
  height: 91px;
  padding: 11px 16px 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.FutureTrading-menu div.box h1 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.FutureTrading-menu div.box h2 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
}

.FutureTrading-menu div.box input {
  height: 25px;
  padding: 0 8px;
  border-radius: 4px;
  background-color: #161514;
  border: none;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.FutureTrading-menu div.box button.dropdown-button {
  width: 90px;
  height: 32px;
  padding: 0 6px;
  border-radius: 8px;
  border: none;
  background-color: #1c1b1a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
}
.FutureTrading-menu div.box div.dropdown {
  position: relative;
  display: inline-block;
}
.FutureTrading-menu div.box div.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffdab9;
  width: 90px;
  height: 30px;
  border-radius: 8px;
  background-color: transparent;
  border: none;
}
.FutureTrading-menu div.box div.dropdown-content button {
  text-decoration: none;
  display: block;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
  width: 100%;
  height: 32px;
  background-color: #242322;
  align-items: center;
  border: none;
}
.FutureTrading-menu div.box div.dropdown-content button:hover {
  background-color: #fff;
  color: #242322;
  border: none;
}
.FutureTrading-menu div.box div.dropdown:hover .dropdown-content {
  display: block;
}
.FutureTrading-menu div.box div.dropdown:hover .dropdown-button {
  background-color: #fff;
  color: #242322;
  border: none;
}

.FutureTrading-menu button.sell {
  width: 125px;
  height: 90px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #e0be7a;
  background-color: #242322;
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.tag-long {
  width: 55px;
  height: 20px;
  background: #91bc6a;
  position: relative;
  padding: 3px;

  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.tag-long:after {
  position: absolute;
  left: -10px;
  top: 0;
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #91bc6a;
}

.tag-short {
  width: 55px;
  height: 20px;
  background: #c96161;
  position: relative;
  padding: 3px;

  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.tag-short:after {
  position: absolute;
  left: -10px;
  top: 0;
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #c96161;
}

.FutureTrading-list div.ticket div.arrow-long {
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-bottom: 8px solid #91bc6a;
  border-right: 5px solid transparent;
}

.FutureTrading-list div.ticket div.arrow-short {
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-top: 8px solid #c96161;
  border-right: 5px solid transparent;
}

.FutureTrading-list div.bettingList div.ticket div.point {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.FutureTrading-list div.bettingList div.ticket div.point div.arrow-long {
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-bottom: 8px solid #91bc6a;
  border-right: 5px solid transparent;
}

.FutureTrading-list div.bettingList div.ticket div.point div.arrow-short {
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-top: 8px solid #c96161;
  border-right: 5px solid transparent;
}

.FutureTrading-chart div.spread {
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-color: #000;
  border-top: 2px dashed #fff;
  border-bottom: 2px dashed #fff;
}

.FutureTrading-positions {
  width: 1525px;
  height: 274px;
  border-radius: 8px;
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.FutureTrading-positions div.title {
  padding: 10px 20px;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.FutureTrading-positions div.line {
  width: 100%;
  height: 1px;
  background-color: #fff;
  opacity: 0.15;
}

.FutureTrading-positions div.cols {
  display: grid;
  grid-template-columns: 175px 176px 175px 166px 176px 196px 196px 142px 96px;
  align-items: center;
  padding: 5px 10px 5px 17px;
}

.FutureTrading-positions div.cols h1 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
  opacity: 0.6;
}

.FutureTrading-positions div.long {
  display: grid;
  grid-template-columns: 175px 176px 175px 166px 176px 196px 196px 142px 96px;
  align-items: center;
  padding: 5px 10px 5px 17px;
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.05);
  height: 40px;
  background-color: rgba(145, 188, 106, 0.05);
}

.FutureTrading-positions div.long h1 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
}

.FutureTrading-positions div.long h2 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #91bc6a;
  text-align: left;
}

.FutureTrading-positions div.long h3 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c96161;
  text-align: left;
}

.FutureTrading-positions div.long button {
  width: 96px;
  height: 30px;
  padding: 5px;
  border-radius: 4px;
  border: solid 1px #e0be7a;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
  background-color: transparent;
}

.FutureTrading-positions div.short {
  display: grid;
  grid-template-columns: 175px 176px 175px 166px 176px 196px 196px 142px 96px;
  align-items: center;
  padding: 5px 10px 5px 17px;
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.05);
  height: 40px;
  background-color: rgba(201, 97, 97, 0.05);
}

.FutureTrading-positions div.short h1 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
}

.FutureTrading-positions div.short h2 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #91bc6a;
  text-align: left;
}

.FutureTrading-positions div.short h3 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c96161;
  text-align: left;
}

.FutureTrading-positions div.short button {
  width: 96px;
  height: 30px;
  padding: 5px;
  border-radius: 4px;
  border: solid 1px #e0be7a;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
  background-color: transparent;
}

.FutureTrading-pricetag {
  width: 90px;
  height: 30px;
  background: #ffffff;
  position: relative;
  padding: 3px;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.FutureTrading-pricetag:after {
  position: absolute;
  left: -15px;
  top: 0;
  content: '';
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #ffffff;
}

.FutureTrading-pricetag-long {
  width: 90px;
  height: 30px;
  background: #91bc6a;
  position: relative;
  padding: 3px;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.FutureTrading-pricetag-long:after {
  position: absolute;
  left: -15px;
  top: 0;
  content: '';
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #91bc6a;
}

.FutureTrading-pricetag-short {
  width: 90px;
  height: 30px;
  background: #c96161;
  position: relative;
  padding: 3px;

  font-family: NotoSansKR;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.FutureTrading-pricetag-short:after {
  position: absolute;
  left: -15px;
  top: 0;
  content: '';
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #c96161;
}

.FutureTrading-chart div.chart-menu {
  position: absolute;
  left: 15px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 30px;
  height: 120px;
  z-index: 2;
}

.FutureTrading-chart div.chart-menu button {
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 4px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  border: none;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

.FutureTrading-chart div.chart-menu button:disabled {
  border: solid 1px #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.FutureTrading-chart div.chart-menu button div.text {
  opacity: 0.35;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.FutureTrading-chart div.chart-menu button:disabled div.text {
  opacity: 1;
}

.FutureTrading-chart div.chart-menu button img {
  object-fit: contain;
  width: 20px;
  height: 20px;
  opacity: 0.35;
}

.FutureTrading-chart div.chart-menu button:disabled img {
  opacity: 1;
}

.FutureTrading-chart div.chart-select {
  position: absolute;
  left: 50px;
  bottom: 60px;
  display: flex;
  flex-direction: column;
  width: 140px;
  border-radius: 4px;
  background-color: #242322;
  z-index: 2;
}

.FutureTrading-chart div.chart-select button {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0;
  border-radius: 4px;
  background: none;
  border: none;
}

.FutureTrading-chart div.chart-select button img {
  width: 17px;
  height: 17px;
  overflow: hidden;
  object-fit: contain;
}

.FutureTrading-chart div.chart-select button h1 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.FutureTrading-chart div.chart-select button:disabled h1 {
  color: #e0be7a;
}

.FutureTrading-chart div.tick-select {
  position: absolute;
  left: 50px;
  bottom: 20px;
  width: 310px;
  padding: 10px;
  border-radius: 4px;
  background-color: #242322;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.FutureTrading-chart div.tick-select h1 {
  font-family: NotoSansKR;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.FutureTrading-chart div.tick-select h2 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e0be7a;
}

.FutureTrading-chart div.tick-select div.buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(6, 1fr);
  column-gap: 10px;
  row-gap: 0px;
  grid-auto-flow: column;
}

.FutureTrading-chart div.tick-select div.buttons button {
  height: 30px;
  padding: 5px 10px;
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.15);
  background-color: #242322;
  border: none;
  font-family: NotoSansKR;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.FutureTrading-chart div.tick-select div.buttons button:disabled {
  box-shadow: inset 0 -1px 0 0 #e0be7a;
  background-color: rgba(255, 255, 255, 0.05);
  color: #e0be7a;
}

.FutureTrading-chart div.tick-select div.auto {
  display: flex;
  height: 20px;
  align-items: center;
}

.FutureTrading-chart div.tick-select div.auto button.on {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: #e0be7a;
  border: none;
  padding: 0;
}

.FutureTrading-chart div.tick-select div.auto button.off {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border-color: #e0be7a;
  background-color: transparent;
  padding: 0;
  background: none;
  border-style: solid;
}

.FutureTrading-chart div.tick-select div.auto h1 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
}

.FutureTrading-chart div.period-select {
  position: absolute;
  left: 50px;
  bottom: 20px;
  width: 210px;
  padding: 10px;
  border-radius: 4px;
  background-color: #242322;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.FutureTrading-chart div.period-select h1 {
  font-family: NotoSansKR;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.FutureTrading-chart div.period-select h2 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e0be7a;
}

.FutureTrading-chart div.period-select div.buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  column-gap: 10px;
  row-gap: 0px;
  grid-auto-flow: column;
}

.FutureTrading-chart div.period-select div.buttons button {
  height: 30px;
  padding: 5px 10px;
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.15);
  background-color: #242322;
  border: none;
  font-family: NotoSansKR;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.FutureTrading-chart div.period-select div.buttons button:disabled {
  box-shadow: inset 0 -1px 0 0 #e0be7a;
  background-color: rgba(255, 255, 255, 0.05);
  color: #e0be7a;
}

.Notice-box {
  width: 820px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Notice-box-title {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e0be7a;
}

.Notice-row-h1 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.Notice-row-h2 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.Notice-row-h3 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
  opacity: 0.6;
}

.Notice-main {
  width: 820px;
  height: auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 10px;
}

.Notice-main-h1 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.Notice-main-h2 {
  height: 35px;
  margin-bottom: 10px;
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.Notice-main-h3 {
  height: 15px;
  margin-bottom: 10px;
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aeaeae;
  text-align: left;
}

.Notice-main-h4 {
  margin: 20px 0;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.63px;
  text-align: justify;
  color: #ffffff;
}

.Notice-main-h5 {
  height: 35px;
  margin-bottom: 10px;
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #91bc6a;
  text-align: left;
}

.Notice-main-line {
  height: 1px;
  border: solid 1px #ffffff;
}

.Notice-info-h1 {
  height: 20px;
  margin-bottom: 4px;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.63px;
  text-align: left;
  color: #ffffff;
}

.Notice-info-h2 {
  height: 26px;
  margin-bottom: 4px;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: left;
  color: #e0be7a;
  text-decoration: underline;
  padding: 0;
  border: none;
  background: none;
}

.Notice-info-h3 {
  height: 15px;
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #aeaeae;
}

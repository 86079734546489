.ForgetAccounts-main {
  margin: 0 auto;
  width: 940px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: auto;
  padding-top: 120px;
  padding-bottom: 180px;
}

.ForgetAccounts-logoside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 87.5px;
}

.ForgetAccounts-logoside-logo {
  width: 283px;
  height: 283px;
  object-fit: contain;
}

.ForgetAccounts-logoside-h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.ForgetAccounts-logoside-h2 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.ForgetAccoutns-infoside {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.ForgetAccoutns-infoside-backbtn {
  width: 176px;
  height: 32px;
  padding: 5px 10px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  background: none;
  text-align: center;
}

.ForgetAccoutns-infoside-box {
  width: 420px;
  height: auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.ForgetAccoutns-infoside-box-h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  opacity: 0.4;
  background: none;
  border: none;
  text-align: left;
  padding-left: 0px;
}

.ForgetAccoutns-infoside-box-h1:disabled {
  opacity: 1;
}

.ForgetAccoutns-infoside-box-h2 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.ForgetAccoutns-infoside-box-input {
  width: 100%;
  padding: 5px 10px;
  border-radius: 8px;
  border: solid 1px #ffffff;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background: none;
}

.ForgetAccoutns-infoside-box-btn1 {
  width: 140px;
  height: 32px;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #c2a76c;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  border-color: transparent;
}

.ForgetAccoutns-infoside-box-h3 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.ForgetAccoutns-infoside-box-btn2 {
  height: 44px;
  border-radius: 8px;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  align-items: center;
  color: #1c1b1a;
  border-color: transparent;
}

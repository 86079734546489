.Introduction-main {
  width: 940px;
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.Introduction-title {
  height: 52px;
  font-family: NotoSansKR;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e0be7a;
  padding: 0;
  background: none;
  background-color: transparent;
  border: none;
  opacity: 0.4;
}

.Introduction-title:disabled {
  opacity: 1;
}

.Introduction-tab-box {
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.Introduction-tab {
  width: auto;
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  background: none;
  border: none;
  opacity: 0.4;
}

.Introduction-tab:disabled {
  opacity: 1;
}

.Introduction-h1 {
  height: auto;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.71px;
  text-align: left;
  color: #ffffff;
}

.Introduction-h2 {
  height: auto;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: justify;
  color: #e0be7a;
}

.Introduction-h3 {
  height: auto;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.71px;
  text-align: left;
  color: #aeaeae;
}

.Introduction-h4 {
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #ffffff;
}

.Introduction-rewards {
  width: 820px;
  height: 270px;
  border-radius: 8px;
  border: solid 2px #e0be7a;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}

.Introduction-button {
  width: 64px;
  height: 64px;
}

.Introduction-long {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #91bc6a;
}

.Introduction-short {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #c96161;
}

.Introduction-stage-box {
  height: auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Introduction-stage-box-img {
  height: 127px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Introduction-stage-box-h1 {
  height: auto;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.Introduction-stage-box-h2 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Introduction-stage-box-h5 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.63px;
  text-align: justify;
  color: #ffffff;
}

.Introduction-point-h1 {
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.Introduction-point-h2 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Introduction-point-h3 {
  opacity: 0.5;
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Introduction-stage-box-box {
  height: 106px;
  padding: 17px 30px;
  border-radius: 4px;
  background-color: #1c1b1a;
}

.Introduction-stage-box-box-h1 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Introduction-stage-box-box-h2 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.Introduction-stage-box-h3 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: justify;
}

.Introduction-stage-box-h4 {
  opacity: 0.5;
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.Introduction-stage-box-description2 {
  height: auto;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: left;
  letter-spacing: 0.63px;
  color: #aeaeae;
}

.Introduction-wallet-box {
  width: 460px;
  height: auto;
  padding: 20px 15px;
  border-radius: 8px;
  background-color: #242322;
}

.Introduction-gotochallenge {
  height: auto;
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: justify;
  color: #e0be7a;
  text-decoration: underline;
}

.Introduction-rewards-round {
  height: 32px;
  padding: 1px 20px 1px;
  border: solid 1px #353535;
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-align: center;
}

.Introduction-rewards-round-reward {
  height: 82px;
  border: solid 1px #353535;
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Introduction-rewards-label {
  text-shadow: 0 0 4px #000000;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #91bc6a;
  position: absolute;
  top: 50px;
}

.Mypage-main {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  width: 1180px;
  margin: 0 auto;
  height: auto;
  overflow: hidden;
  padding-top: 120px;
  padding-bottom: 180px;
}

.Mypage-userinfo-box {
  width: 380px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.Mypage-exchanges-tab {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  opacity: 0.4;
  background: none;
  border: none;
  padding: 0;
}

.Mypage-exchanges-tab:disabled {
  opacity: 1;
}

.Mypage-exchanges-box {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  border: solid 1px #1c1b1a;
  background-color: #242322;
}

.Mypage-exchanges-h1 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
}

.Mypage-exchanges-arrow {
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  transform: rotate(135deg);
}

.Mypage-exchanges-arrow::after {
  content: '';
  display: block;
  width: 1px;
  height: 22px;
  background-color: #fff;
  transform: rotate(-45deg) translate(7px, 3px);
  left: 0;
  top: 0;
}

.Mypage-exchanges-h2 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #e0be7a;
}

.Mypage-exchanges-h3 {
  opacity: 0.65;
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #fff;
}

.Mypage-exchanges-h4 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
}

.Mypage-exchanges-dot1 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d8d8d8;
}

.Mypage-exchanges-h5 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #91bc6a;
}

.Mypage-exchanges-dot2 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #91bc6a;
}

.Mypage-exchanges-more {
  height: 40px;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #1c1b1a;
  background-color: #242322;
  border: none;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.Mypage-wallet-box {
  width: 420px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.Mypage-title {
  height: 35px;
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e0be7a;
}

.Mypage-info-label {
  height: 20px;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.Mypage-info-h1 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-decoration: underline;
}

.Mypage-info-h2 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
  text-decoration: underline;
}

.Mypage-info-b1 {
  width: 100px;
  height: 32px;
  padding: 6px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  background: none;
}

.Mypage-info-b2 {
  width: 100px;
  height: 32px;
  padding: 6px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c1b1a;
}

.Mypage-info-b3 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-decoration: underline;
  background: none;
  padding: 0;
  border: none;
}

.Mypage-info-input {
  width: 100%;
  height: 32px;
  padding: 6px 10px 6px 10px;
  border-radius: 8px;
  border: solid 1px #ffffff;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background: none;
}

.Mypage-info-input:disabled {
  opacity: 0.3;
}

.Mypage-info-button {
  margin-left: 20px;
  width: 54px;
  height: 32px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
  background-color: transparent;
  background: none;
}

.Mypage-info-info {
  opacity: 0.65;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
}

.Mypage-info-support {
  opacity: 0.65;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #e0be7a;
  position: absolute;
  right: 0;
  bottom: 0;
  text-decoration: underline;
  padding: 0;
  background: none;
  border: none;
}

.Mypage-wallet-label {
  height: 34px;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.Mypage-wallet-innerbox {
  position: relative;
  height: auto;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #1c1b1a;
}

.Mypage-wallet-innerbox-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #e0be7a;
}

.Mypage-wallet-innerbox-h1 {
  width: 160px;
  height: auto;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e0be7a;
}

.Mypage-wallet-innerbox-h2 {
  height: auto;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

.Mypage-wallet-innerbox-h3 {
  height: auto;
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c96161;
  text-align: left;
}

.Mypage-wallet-innerbox-h4 {
  height: auto;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #91bc6a;
  text-align: left;
}

.Mypage-wallet-innerbox-input {
  width: 191px;
  height: 32px;
  padding: 6px 10px;
  border-radius: 8px;
  border: solid 1px #ffffff;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background: none;
  color: #ffffff;
}

.Mypage-wallet-innerbox-h5 {
  height: 24px;
  margin: 0 10px;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Mypage-wallet-innerbox-h6 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c96161;
}

.Mypage-wallet-innerbox-h7 {
  height: auto;
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aeaeae;
  text-align: left;
}

.Mypage-wallet-innerbox-h8 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Mypage-wallet-innerbox-h9 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c96161;
  text-align: left;
}

.Mypage-wallet-innerbox-h10 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #ffffff;
}

.Mypage-wallet-innerbox-btn-full {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 3px 10px;
  border-radius: 8px;
  background-color: #c2a76c;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border-color: transparent;
  color: #1c1b1a;
}

.Mypage-wallet-innerbox-btn1 {
  width: 100px;
  height: 32px;
  padding: 3px 10px;
  border-radius: 8px;
  background-color: #c2a76c;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border-color: transparent;
  color: #1c1b1a;
}

.Mypage-wallet-innerbox-token {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
  overflow-wrap: break-word;
}

.Mypage-wallet-innerbox-btn2 {
  width: 100px;
  height: 32px;
  padding: 3px 10px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  background: none;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.Mypage-wallet-innerbox-btn3 {
  width: 126px;
  height: 32px;
  padding: 3px 10px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  background: none;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.Mypage-enquiry-box {
  margin: 160px auto 0;
  width: 420px;
  height: 460px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #1c1b1a;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.Mypage-enquiry-title {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e0be7a;
}

.Mypage-modal-cancelbtn {
  width: 54px;
  height: 32px;
  padding: 4px;
  border-radius: 8px;
  border: solid 1px #c96161;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c96161;
  background: none;
}

.Mypage-modal-confirmbtn {
  width: 54px;
  height: 32px;
  padding: 4px;
  border-radius: 8px;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  background-color: #e0be7a;
  border-color: transparent;
}

.Mypage-modal-content {
  width: 380px;
  height: 263px;
  position: relative;
}

.Mypage-modal-textarea {
  width: 380px;
  height: 263px;
  padding: 6px 10px;
  border-radius: 8px;
  border: solid 1px #ffffff;
  background: none;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.Mypage-modal-content-words {
  font-family: NotoSansKR;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: right;
  position: absolute;
  right: 5px;
  bottom: 8px;
}

.Toastify__toast-container {
  width: 200px;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Mypage-recommendees-modal {
  margin: 160px auto 0;
  width: 420px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #1c1b1a;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.Mypage-recommendees-id {
  padding: 7px;
  border: solid 1px rgba(255, 255, 255, 0.2);
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background: none;
}

.Mypage-modal-page {
  margin: 0 5px;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background: none;
  border: none;
  padding: 0;
  color: #ffffff;
}

.Mypage-modal-page:disabled {
  font-weight: bold;
  color: #e0be7a;
}

.Mypage-modal-h1 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #c96161;
}

.Mypage-modal-h2 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: #ffffff;
}

.Mypage-userinfo-box-b1 {
  width: 100%;
  height: 32px;
  padding: 6px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
  background-color: transparent;
}

.Mypage-no-membership {
  width: 178px;
  height: 95px;
  padding: 14px;
  opacity: 1;
  border-radius: 12px;
  border: solid 3px #979797;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Mypage-no-membership h1 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  opacity: 1;
}

.Mypage-no-membership h2 {
  font-family: NotoSansKR;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  opacity: 1;
}

.Mypage-userinfo-box-b2 {
  width: 150px;
  height: 32px;
  padding: 6px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
  background-color: transparent;
}

.Mypage-userinfo-box-b3 {
  width: 121px;
  height: 32px;
  padding: 6px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
  background-color: transparent;
}

.Myapge-incentive-h1 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
}

.Myapge-incentive-h2 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.Myapge-incentive-h3 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
}

.Mypage-membership-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Mypage-membership-modal h1 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.Mypage-membership-modal h2 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.Mypage-membership-modal-btn {
  width: 112px;
  height: 32px;
  padding: 6px;
  border-radius: 8px;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  border: none;
}

.Mypage-membership-approved {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #91bc6a;
}

.Mypage-total-box {
  width: 380px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Mypage-total-box h1 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: center;
}

.Mypage-total-inner-box {
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  border: solid 1px #1c1b1a;
  background-color: #242322;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.Mypage-total-inner-box h1 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: center;
}

.Mypage-total-inner-box h2 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.SendEnquiry-box {
  width: 820px;
  height: 520px;
  margin: 96px auto 180px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.SendEnquiry-title {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.SendEnquiry-label {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.SendEnquiry-input {
  padding: 6px;
  border-radius: 8px;
  border: solid 1px #ffffff;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
  background: none;
}

.SendEnquiry-textarea {
  height: 256px;
  padding: 6px 10px;
  border-radius: 8px;
  border: solid 1px #ffffff;
  background: none;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.SendEnquiry-toggle-on {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #e0be7a;
  padding: 0;
  border: none;
}

.SendEnquiry-toggle-off {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border-color: #e0be7a;
  background-color: transparent;
  padding: 0;
  background: none;
  border-style: solid;
}

.SendEnquiry-is-private-label {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.SendEnquiry-send-btn {
  width: 120px;
  height: 44px;
  padding: 12px;
  border-radius: 8px;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  border: none;
}

.Challenge-stage {
  width: 465px;
  height: 45px;
  border-radius: 8px;
  border: solid 2px #e0be7a;
  background-color: #1c1b1a;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 100%;
}

.Challenge-stage-label1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  border: none;
  background-color: #e0be7a;
  border-radius: 8px;
  margin: -4px;
}

.Challenge-stage-label2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
  border: none;
  background: none;
}

.Challenge-mode {
  width: 310px;
  height: 45px;
  border-radius: 8px;
  border: solid 2px #e0be7a;
  background-color: #1c1b1a;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100%;
}

.Challenge-infobox {
  width: 300px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.Challenge-infobox-h1 {
  margin: 13px 20px;
  font-family: NotoSansKR;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.Challenge-infobox-h2 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.Challenge-infobox-h3 {
  font-family: NotoSansKR;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e0be7a;
  display: flex;
  align-items: center;
}

.Challenge-infobox-h4 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.Challenge-infobox-control {
  margin: 0 10px 10px;
  height: 181px;
  border-radius: 4px;
  border: solid 1px #c2a76c;
  background: none;
  position: relative;
}

.Challenge-bet-btn {
  background: none;
  padding: 0;
  border: none;
}

.Challenge-bet-btn:disabled {
  opacity: 0.3;
}

.Challenge-infobox-long-label {
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #91bc6a;
}

.Challenge-infobox-short-label {
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c96161;
}

.Challenge-infobox-control-label {
  width: 90px;
  height: 11px;
  font-family: NotoSansKR;
  font-size: 8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c2a76c;
  position: absolute;
  top: -9px;
  left: 95px;
  background-color: #242322;
}

.Challenge-infobox-stopbtn {
  height: 44px;
  margin: 0 10px 10px;
  border-radius: 8px;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Challenge-infobox-stopbtn:disabled {
  opacity: 0.3;
}

.Challenge-rewards-box {
  width: 820px;
  height: 270px;
  border-radius: 8px;
  border: solid 2px #e0be7a;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}

.Challenge-rewards-title {
  height: 42px;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Challenge-rewards-h1 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: center;
}

.Challenge-rewards-round {
  height: 32px;
  padding: 1px 20px 1px;
  border: solid 1px #353535;
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-align: center;
}

.Challenge-rewards-round-reward {
  height: 82px;
  border: solid 1px #353535;
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Challenge-rewards-label {
  font-family: NotoSansKR;
  text-shadow: 0 0 4px #000;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #91bc6a;
  position: absolute;
  top: 50px;
  z-index: 1;
}

.Challenge-rewards-label-shadow {
  -webkit-text-stroke: 8px rgba(0, 0, 0, 0.75);
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #91bc6a;
  position: absolute;
  top: 50px;
}

.Challenge-footer {
  height: 76px;
  box-shadow: inset 0 1px 0 0 #a89670;
  background-color: #1c1b1a;
}

.Challenge-footer-copyrights {
  opacity: 0.4;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Challenge-footer-support-title {
  opacity: 0.75;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Challenge-footer-support {
  opacity: 0.4;
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Challenge-pointbtn-title {
  font-family: NotoSansKR;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.71px;
  text-align: center;
  color: #e0be7a;
  height: 70px;
  overflow: hidden;
}

.Challenge-pointbtn-description {
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.71px;
  text-align: center;
  color: #ffffff;
  height: 30px;
  overflow: hidden;
}

.Challenge-modebtn {
  width: 380px;
  height: 240px;
  border-radius: 10px;
  border-color: transparent;
  border: none;
  overflow: hidden;
  position: relative;
}

.Challenge-modebtn-selected {
  width: 380px;
  height: 240px;
  border-radius: 10px;
  background-color: #e0be7a;
  border: none;
  overflow: hidden;
  position: relative;
}

.Challenge-modebtn-label {
  font-family: NotoSansKR;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.Challenge-modebtn-content {
  width: 380px;
  height: 240px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 25px;
}

.Challenge-modebtn-label-selected {
  font-family: NotoSansKR;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c1b1a;
}

.Challenge-modebtn-h1 {
  width: 320px;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Challenge-gameinfo-box {
  width: 420px;
  height: auto;
  margin: 60px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.Challenge-gameinfo-box-h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.Challenge-gameinfo-box-h2 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
}

.Challenge-gameinfo-box-h3 {
  font-family: NotoSansKR;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.Challenge-gameinfo-prebtn {
  padding: 12px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  background: none;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.Challenge-gameinfo-prebtn:disabled {
  opacity: 0.3;
}

.Challenge-gameinfo-nextbtn {
  padding: 12px;
  border-radius: 8px;
  border: none;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
}

.Challenge-gameinfo-nextbtn:disabled {
  opacity: 0.3;
}

.Challenge-stagebtn {
  width: 300px;
  height: 240px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  background: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 25px;
  font-family: NotoSansKR;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.Challenge-stagebtn-selected {
  width: 300px;
  height: 240px;
  border-radius: 8px;
  background-color: #e0be7a;
  border: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 25px;
  font-family: NotoSansKR;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c1b1a;
}

.Challenge-record-box {
  width: 620px;
  height: 45px;
  border-radius: 8px;
  border: solid 2px #e0be7a;
  background-color: #1c1b1a;
  overflow: hidden;
}

.Challenge-record-top-h1 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Challenge-record-top-h2 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  background-color: #e0be7a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Challenge-record-h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #353535;
}

.Challenge-record-h2 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #91bc6a;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #353535;
}

.Challenge-record-h3 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c96161;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #353535;
}

.Challenge-record-h4 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #353535;
}

.Challenge-record-line {
  width: 1px;
  height: 27px;
  border: solid 1px #353535;
}

.Challenge-result-background {
  width: 100%;
  height: 100%;
  background: none;
}

.Challenge-result-modal {
  width: 420px;
  height: 481px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #1c1b1a;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.Challenge-result-modal-h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.Challenge-result-modal-b1 {
  border-radius: 4px;
  border: solid 1px #e0be7a;
  background: none;
  padding: 15px 60px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.Challenge-result-modal-h2 {
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e0be7a;
}

.Challenge-result-modal-h3 {
  font-family: NotoSansKR;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.Challenge-result-modal-h4 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Challenge-result-modal-h5 {
  font-family: SpoqaHanSans;
  font-size: 56px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
  margin: 20px;
}

.Challenge-result-modal-h6 {
  font-family: NotoSansKR;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.Challenge-result-modal-b2 {
  height: 44px;
  padding: 12px;
  border-radius: 8px;
  background-color: #e0be7a;
  border: none;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
}

.Challenge-roundend-line {
  width: 1px;
  height: 422px;
  border: solid 1px #e0be7a;
}

.Challenge-roundend-label {
  margin: 4px;
  font-family: NotoSansKR;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.Challenge-betstart-line {
  width: 1px;
  height: 422px;
  border: solid 1px #ffffff;
}

.Challenge-betstart-label {
  margin: 4px;
  font-family: NotoSansKR;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Challenge-pricetag {
  width: 55px;
  height: 20px;
  background: #ffffff;
  position: relative;
  padding: 3px;

  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
}

.Challenge-pricetag:after {
  position: absolute;
  left: -10px;
  top: 0;
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #ffffff;
}

.Challenge-pricetag-long {
  width: 55px;
  height: 20px;
  background: #91bc6a;
  position: relative;
  padding: 3px;

  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
}

.Challenge-pricetag-long:after {
  position: absolute;
  left: -10px;
  top: 0;
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #91bc6a;
}

.Challenge-pricetag-short {
  width: 55px;
  height: 20px;
  background: #c96161;
  position: relative;
  padding: 3px;

  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
}

.Challenge-pricetag-short:after {
  position: absolute;
  left: -10px;
  top: 0;
  content: '';
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #c96161;
}

.Challenge-time-h1 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Challenge-time-h2 {
  font-family: NotoSansKR;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.Toastify__toast-container {
  width: 200px;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.Challenge-bitcoin-label {
  opacity: 0.5;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
  position: absolute;
  top: 12px;
  left: 45px;
}

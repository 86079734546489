.Purchase-box {
  width: 420px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.35);
  background-color: #242322;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Purchase-box-g1 {
  display: flex;
  align-items: center;
}

.Purchase-box-g1 h1 {
  font-family: NotoSansKR;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.Purchase-box-g1 h2 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.Purchase-box-g1 h3 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.Purchase-box h1 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
}

.Purchase-box-box {
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #1c1b1a;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Purchase-box-box h1 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.Purchase-box-box h2 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
}

.Purchase-box-box-b1 {
  width: 100px;
  height: 32px;
  padding: 6px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  background-color: transparent;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

.Purchase-box-box h3 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c96161;
  text-align: left;
}

.Purchase-box-box h4 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aeaeae;
  text-align: left;
}

.Purchase-box-box h5 {
  font-family: NotoSansKR;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
  text-align: left;
}

.Purchase-box-box h6 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c96161;
  text-align: left;
}

.Purchase-box-box-b2 {
  width: 100%;
  height: 32px;
  padding: 6px;
  border-radius: 8px;
  background-color: #c2a76c;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  border: none;
}

.Purchase-box-box-b2:disabled {
  opacity: 0.5;
}

.Purchase-box-box2 {
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #1c1b1a;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Purchase-box-box2 div {
  display: flex;
  justify-content: space-between;
}

.Purchase-box-box2 div h1 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.Purchase-box-box-b3 {
  width: 170px;
  height: 32px;
  padding: 6px;
  border-radius: 8px;
  border: solid 1px #e0be7a;
  background-color: transparent;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e0be7a;
}

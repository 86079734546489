.Main-banner {
  width: 100%;
  height: 1004px;
  margin: auto;
  background: #000;
  overflow: hidden;
  position: relative;
}

.Main-banner img.banner {
  width: 1920px;
  height: 1080px;
  position: absolute;
  bottom: 0;
  left: calc(50% - 960px);
}

.Main-banner div.more {
  width: 300px;
  position: absolute;
  bottom: 88px;
  left: calc(50% - 150px);
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Main-banner img.arrow {
  width: 26px;
  height: 12px;
  position: absolute;
  bottom: 60px;
  left: calc(50% - 13px);
}

.Main-banner div.main {
  position: absolute;
  top: 0;
  width: 100%;
  height: 1004px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Main-banner div.main img.logo {
  width: 207px;
  height: 207px;
}

.Main-banner div.main h1 {
  height: 70px;
  font-family: NotoSansKR;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Main-banner div.main h2 {
  height: 24px;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Main-banner div.main div.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Main-banner1-descriptions {
  position: absolute;
  width: 940px;
  height: 396px;
  top: 0;
  left: calc(50% - 470px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Main-introduce {
  width: 100%;
  height: 276px;
  background: #161514;
  position: relative;
  margin: auto;
}

.Main-introduce div.deco {
  width: 1920px;
  height: 100%;
  position: relative;
  margin: auto;
}

.Main-introduce div.deco img.right {
  width: 564px;
  height: 174px;
  position: absolute;
  right: 0;
  top: 0;
  object-fit: fill;
}

.Main-introduce div.deco img.left {
  width: 463px;
  height: 210px;
  position: absolute;
  left: 0;
  bottom: 0;
  object-fit: fill;
}

.Main-introduce div.main {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.Main-introduce div.main div.group {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
}

.Main-introduce div.main div.group div.circle {
  width: 56px;
  height: 56px;
  background-color: #e0be7a;
  border-radius: 50%;
}

.Main-introduce div.main div.group h1 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Main-introduce div.main div.group h2 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Main-about {
  width: 100%;
  height: 701px;
  background-image: linear-gradient(to bottom, #1c1b1a, #161514);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Main-about img.logo {
  width: 56px;
  height: 56px;
  object-fit: contain;
}

.Main-about h1 {
  font-family: NotoSansKR;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Main-about h2 {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.Main-about div.boxes {
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 280px;
}

.Main-about div.boxes div.box {
  width: 300px;
  display: flex;
  padding: 36px 15px 0px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background-color: #242322;
}

.Main-about div.boxes div.box div.circle {
  width: 56px;
  height: 56px;
  background-color: #e0be7a;
  border-radius: 50%;
}

.Main-about div.boxes div.box h1 {
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e0be7a;
}

.Main-about div.boxes div.box h2 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #fff;
  text-align: left;
}

.Main-games {
  width: 100%;
  height: 515px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

.Main-games div.group {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.Main-games div.group h1 {
  font-family: NotoSansKR;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  height: 40px;
}

.Main-games div.group h2 {
  font-family: NotoSansKR;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  height: 66px;
}

.Main-games div.group img.screenshot {
  width: 300px;
  height: 168px;
  object-fit: contain;
}

.Main-start {
  width: 100%;
  height: 223px;
  background: #161514;
  position: relative;
  margin: auto;
}

.Main-start div.deco {
  width: 1920px;
  height: 100%;
  position: relative;
  margin: auto;
}

.Main-start div.deco img.right {
  width: 564px;
  height: 174px;
  position: absolute;
  right: 0;
  top: 0;
  object-fit: fill;
}

.Main-start div.deco img.left {
  width: 463px;
  height: 210px;
  position: absolute;
  left: 0;
  bottom: 0;
  object-fit: fill;
}

.Main-start div.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.Main-start div.main h1 {
  font-family: NotoSansKR;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.link1 {
  width: 149px;
  height: 44px;
  padding: 12px 0px;
  border-radius: 8px;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
  border: none;
}

.link2 {
  width: 134px;
  height: 44px;
  padding: 12px 0px;
  border-radius: 8px;
  border: none;
  background-color: #e0be7a;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1c1b1a;
}
